<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="card rounded-lg border border-dark">
        <div class="row">
          <div class="row my-5 mx-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Profile page</h5>
                <hr />
                <form @submit="handleUserType">
                  <h6>User Type</h6>
                   <div v-if="EmailBildiri" class="alert alert-danger" ><center>You can send your abstracts to meteouzal@itu.edu.tr</center></div>
                  <div class="row">
                    <div class="col">
                      <select class="form-select" v-model="usertype">
                        <option value="BL">with Paper</option>
                        <option value="BS">without Paper</option>
                      </select>
                    </div>
                    <div class="col">
                      <button
                        type="submit"
                        class="btn btn-primary btn-block pr-5"
                        :disabled="loading"
                      >
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span>Save</span>
                      </button>
                      <button style="visibility: hidden;"></button>
                      <button
                        type="button"
                        v-if="constusertype === 'BL'"
                        class="btn btn-primary btn-block"
                        @click="SendtoEmail"
                      >
                        Send document
                      </button>
                    </div>
                  </div>
                </form>
                <form @submit="handleKurum" class="mt-5">
                  <h6>Your institution</h6>
                  <div class="row">
                    <div class="col">
                      <select class="form-select" v-model="getkurum">
                        <option value="ITU">
                          Istanbul Technical University
                        </option>
                        <option value="SAMU">Samsun University</option>
                        <option value="MGM">
                          Turkish State Meteorological Service
                        </option>
                        <option :value="reversedMessage">Other</option>
                      </select>
                      <br />
                      <h6>If you chose other, you can write here</h6>
                      <input
                        class="form-control"
                        v-model="getkurum"
                        type="text"
                      />
                    </div>

                    <div class="col">
                      <button
                        type="submit"
                        class="btn btn-primary btn-block"
                        :disabled="loading2"
                      >
                        <span
                          v-show="loading2"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span>Save</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getAPI } from "@/axios-api";
import { mapState } from "vuex";
import authHeader from "@/services/auth-header.js";
export default {
  name: "Profile",
  data() {
    return {
      EmailBildiri:"",
      usertype: "",
      constusertype: "",
      loading: false,
      loading2: false,
      message: "",
      getkurum: "",
    };
  },
  computed: {
    reversedMessage() {
      return this.getkurum;
    },
    ...mapState(["UserTypeData", "UserKurumData"]),
  },
  created() {
    getAPI
      .get("/usertype/", { headers: authHeader() })
      .then((response) => {
        this.$store.state.UserTypeData = response.data;
        this.usertype = this.$store.state.UserTypeData[0].user_type;
        this.constusertype = this.$store.state.UserTypeData[0].user_type;
      })
      .catch((err) => {
        console.log(err);
      });
    getAPI
      .get("/kurum/", { headers: authHeader() })
      .then((response) => {
        this.$store.state.UserKurumData = response.data;
        this.getkurum = this.$store.state.UserKurumData[0].kurum;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    SendtoEmail() {
      this.EmailBildiri="True"
      this.$notify("You can send your abstracts to meteouzal@itu.edu.tr");
    },
    handleUserType(e) {
      e.preventDefault()
      this.loading = true;
      getAPI
        .put(
          "/usertype/" + this.$store.state.UserTypeData[0].id + "/",
          {
            id: this.$store.state.UserTypeData[0].id,
            user_type: this.usertype,
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.message = "Başarılı";
          this.loading = false;
          location.reload();
          response.data;
        })
        .catch((err) => {
          this.message = "Başarısız";
          this.loading = false;
          location.reload();
          console.log(err);
        });
    },
    handleKurum(e) {
      e.preventDefault()
      this.loading2 = true;
      getAPI
        .put(
          "/kurum/" + this.$store.state.UserKurumData[0].id + "/",
          {
            id: this.$store.state.UserKurumData[0].id,
            kurum: this.getkurum,
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.message = "Başarılı";
          this.loading2 = false;
          location.reload();
          response.data;
        })
        .catch((err) => {
          this.message = "Başarısız";
          this.loading2 = false;
          location.reload();
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
